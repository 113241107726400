/* eslint-disable sonarjs/cognitive-complexity */
import type { AxiosInstance } from 'axios'
import axios from 'axios'

import { useErrorStore } from '@/stores/error'
import { useToasterStore } from '@/stores/toaster'

import { clearEncryptedStorage } from '@/helpers'
import router from '@/router'

const { actions: errorStoreActions } = useErrorStore()
const { state: toasterStoreState } = useToasterStore()

// // Define the structure of a retry queue item
// interface RetryQueueItem {
//   resolve: (value?: any) => void
//   reject: (error?: any) => void
//   config: AxiosRequestConfig
// }

// Create a list to hold the request queue
// const refreshAndRetryQueue: RetryQueueItem[] = []

// Flag to prevent multiple token refresh requests
// const isRefreshing = false

const axiosIns: AxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
  headers: {
    'Content-type': 'application/json',
    'Accept': 'application/json',
  },
  withCredentials: true,
})

// axiosIns.interceptors.request.use(config => {
//   // Retrieve token from localStorage
//   const token = getEncryptedItem('accessToken')?.replace(/"/g, '')
//   const refreshToken: any = getEncryptedItem('refreshToken')?.replace(/"/g, '')

//   // If token is found
//   if (token) {
//     // Get request headers and if headers are undefined assign a blank object
//     config.headers = config.headers || {}

//     // Set authorization header
//     if (config.url === '/v1/auth/refresh-token')
//       config.headers.Authorization = `Bearer ${refreshToken}`
//     else
//       config.headers.Authorization = token ? `Bearer ${token}` : ''
//   }

//   // Return modified config
//   return config
// })

axiosIns.interceptors.response.use(
  response => response,
  async error => {
    // Check if there is a response
    if (error.response) {
      // const originalRequest: AxiosRequestConfig = error.config
      const { status, data, config: errorConfig } = error.response

      // Handle specific HTTP status codes with error messages
      if ([400, 404, 202].includes(status)) {
        if (data?.message && errorConfig.url !== '/v2/payment-links/callback')
          await toasterStoreState.displaySnackbar(data.message, 'error')
      }
      else if (status === 401 && data.message === 'Invalid login details.') {
        await toasterStoreState.displaySnackbar(data.message, 'error')
      }
      else if (status === 401 && data.message === 'Unauthenticated.') {
        await toasterStoreState.displaySnackbar(data?.message, 'error')
        clearEncryptedStorage()
        router.push('/login')

        // if (!isRefreshing) {
        //   isRefreshing = true
        //   try {
        //     // Refresh the access token
        //     const { refreshTokenApi } = authenticationService()
        //     const response = await refreshTokenApi()

        //     if (response?.data?.token)
        //       setEncryptedItem('accessToken', JSON.stringify(response.data.token))

        //     // Update the request headers with the new access token
        //     error.config.headers.authorization = `Bearer ${response.data.token}`

        //     // Retry all requests in the queue with the new token
        //     refreshAndRetryQueue.forEach(({ config, resolve, reject }) => {
        //       (config?.headers as any)['Content-type'] = 'application/json'
        //       axiosIns
        //         .request(config)
        //         .then(res => {
        //           res.data = typeof res.data === 'string' ? JSON.parse(res.data) : res.data
        //           resolve(res)
        //         })
        //         .catch(err => reject(err))
        //     })

        //     // Clear the queue
        //     refreshAndRetryQueue.length = 0

        //     // Retry the original request
        //     return axiosIns(originalRequest)
        //   }
        //   catch (err) {
        //     localStorage.clear()
        //     router.push('/login')

        //     return Promise.reject(err)
        //   }
        //   finally {
        //     isRefreshing = false
        //   }
        // }

        // // Add the original request to the queue
        // return new Promise<void>((resolve, reject) => {
        //   refreshAndRetryQueue.push({ config: originalRequest, resolve, reject })
        // })
      }
      else if (status === 500) {
        await toasterStoreState.displaySnackbar('Internal server error. Please try agin later', 'error')
      }
      else if (status === 403) {
        await toasterStoreState.displaySnackbar(data?.message, 'error')
      }

      // Handle validation errors (status code 422)
      if (status === 422)
        await errorStoreActions.handleApiError(error.response.data.errors ? error.response.data.errors : error.response.data.message.errors)
    }

    return Promise.reject(error)
  },
)

export default axiosIns
